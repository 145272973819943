<template>
	<div></div>
</template>

<script>
// Router forwards to this blank page when the
// path doesn't match user/list/room/artist/artwork
// It's cleaner to stick to the same system
// than to forward to the HttpError view directly

// Stores
import { useHttpErrorStore } from '@/stores/HttpErrorStore'

export default {
	name: 'Blank404Page',
	setup() {
		const httpErrorStore = useHttpErrorStore()
		return { httpErrorStore }
	},
	created() {
		this.httpErrorStore.setError({
			status: 404,
			statusText: 'Not Found',
			origin: 'Blank404.vue',
		})
	},
}
</script>
